<template>
  <div class="main-content">
    <div class="content-block">
      <h2 class="lg-title nb">
        <div class="title-word">更改價格</div>
        <div class="cta-tool">
          <div
            class="cta-btn edit"
            :class="{ active: !edit }"
            @click="clickEdit"
          ></div>
        </div>
      </h2>
      <div class="data-container">
        <div class="data-body">
          <div
            class="data-row change-row"
            :class="{ active: edit }"
            v-for="(product, index) in groupProducts"
            :key="`change-price-prodcut-${index}`"
          >
            <div class="item product">{{ product.name }}</div>
            <div class="item qty-data">
              <div class="tool-wrap">
                <QtyInput
                  :productIndex="index"
                  :quantity="product.sale_price"
                  @quantity-change="updatePrice"
                ></QtyInput>
              </div>
              <div class="qty-dt">
                <div class="single">${{ product.unit_price }}</div>
                <div
                  :class="[
                    product.sale_price === product.origin_sale_price
                      ? 'single'
                      : 'total'
                  ]"
                >
                  ${{ product.sale_price }}
                </div>
              </div>
            </div>
          </div>
          <div class="sum-block">${{ group.subtotal }}</div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-block functional" :class="{ active: edit }">
      <div class="btn-cta" @click="clickEdit">儲 存</div>
    </div>
  </div>
</template>

<script>
import {
  getChangePriceGroupBuyingProductList,
  updateGroupBuyingProducts
} from '@/api/group-buying';
import QtyInput from '@/components/GroupProductQtyInput';

export default {
  components: {
    QtyInput
  },
  data() {
    return {
      edit: false,
      group: {
        id: null,
        group_buying_products: [],
        subtotal: 0
      }
    };
  },
  mounted() {},
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupProducts() {
      return this.group.group_buying_products;
    },
    productsToUpdate() {
      return this.groupProducts
        .filter(ele => ele.sale_price != ele.origin_sale_price)
        .map(ele => {
          return {
            id: ele.id,
            sale_price: ele.sale_price
          };
        });
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.setData();
      }
    },
    edit(newVal, oldVal) {
      if (oldVal === true && this.productsToUpdate.length > 0) {
        updateGroupBuyingProducts({
          group_buying_order_id: this.groupId,
          group_buying_products: this.productsToUpdate
        }).then(() => {
          this.setData();
        });
      }
    }
  },
  methods: {
    setData() {
      getChangePriceGroupBuyingProductList({
        group_buying_order_id: this.groupId
      }).then(data => {
        this.group = data;
      });
    },
    updatePrice(prod) {
      this.group.group_buying_products[prod.index].sale_price = prod.quantity;
    },
    clickEdit() {
      this.edit = !this.edit;
    }
  }
};
</script>
