<template>
  <div class="item-tool">
    <button
      class="reduce"
      data-action="reduce"
      @click="updateProductQuantity"
    ></button>
    <input type="number" min="0" :value="quantity" @input="changeQty" />
    <button
      class="plus"
      data-action="plus"
      @click="updateProductQuantity"
    ></button>
  </div>
</template>

<script>
export default {
  props: {
    quantity: {
      type: Number,
      required: false,
      default() {
        return 0;
      }
    },
    productIndex: {
      type: Number,
      required: false,
      default() {
        return null;
      }
    }
  },
  methods: {
    updateProductQuantity(e) {
      let action = e.target.dataset.action;
      let value = this.quantity;

      if (action === 'reduce') {
        value--;
      } else if (action === 'plus') {
        value++;
      }

      this.emitChange(value);
    },
    changeQty(e) {
      let value = e.target.value;

      this.emitChange(value);
    },
    emitChange(val) {
      if (val <= 0) {
        val = 0;
      }

      this.$emit('quantity-change', {
        index: this.productIndex,
        quantity: parseInt(val)
      });
    }
  }
};
</script>
